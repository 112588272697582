.mc-options {
  top: 45px;
  width: 100%;
  overflow-y: scroll;
  max-height: 200px;
  transition: height 200ms linear 0s;
  border-radius: 12px;
  z-index: 5;
  box-sizing: border-box;

  .mc-option-item:hover {
    background: #F5F9FC;
    border-radius: 8px;

    label {
      color: #8927EF;
    }
  }

  .mc-option-item {
    height: 40px;
    min-height: 40px;
    overflow-x: hidden;

    label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.mc-select {
  border-radius: 12px;

  .organisations-border {
    height: 40px;
    padding: 8px 16px;
    border: 1px solid #ECEEF3;
    border-radius: 16px;
  }
}

.selected-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hidden {
  overflow: hidden;
}
